import React from 'react'
import Layout from '../components/Layout'
const ErrorPage = () => {
  return (
    <Layout>
      <div className="  bg-primary  py-20 th-home--splash">
        <div className="columns py-8 th-home-padpagemob">
          <div className="column col-12 py-2 text-center">
            <h1 className="th-font-av th-home--mobilefonth6" style={{fontSize: '5rem'}}>
              Wuh oh!
            </h1>
            <h2 className="th-font-av th-home--mobilefonthp">You've reached this page in error (404).</h2>
            <p className="p-4">
              If you think something might be broken will you kindly email to let us know? <br />
            </p>
            <br />
            &nbsp;&nbsp;
            <div className="btn btn-sm btn-primary">
              <b>
                {' '}
                <a href="mailto:support@disclosures.io?subject=I think I found a bug (from 404 page)." className=" p-4" style={{color: '#00a6d4'}}>
                  <i className="fas fa-envelope " /> &nbsp;support@disclosures.io
                </a>
              </b>
            </div>
            <h2 className="th-font-av py-20">Thank you!</h2>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage
