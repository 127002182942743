import React, {Component} from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import SEO from './SEO'
import OffCanvas from './OffCanvas'
import SignupFormMob from './SignupFormMob'
export default class extends Component {
  state = {
    open: false,
  }

  onClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  toggleModal = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <SEO />
        <OffCanvas open={this.state.open} onDismiss={this.onClick}>
          <div key="sidebar" className="bg-white" style={{padding: '0rem'}}>
            <div className="pt-2 ">
              <a href="/features" style={{color: '#46b6ff'}}>
                <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                  &nbsp;FEATURES
                </div>
              </a>
            </div>

            <div className="pt-1 ">
              <a href="/agents" style={{color: '#46b6ff'}}>
                <div className="btn  btn-md col-12 text-left  " style={{border: '0px solid blue', color: '#46b6ff'}}>
                  &nbsp;Services <i className="fas fa-hands-helping fa-lg " style={{color: '#46b6ff'}} />
                  &nbsp;
                </div>
              </a>
            </div>

            <div className="pt-1 ">
              <a href="/pricing" style={{color: '#46b6ff'}}>
                <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                  &nbsp;PRICING
                </div>
              </a>
            </div>

            <div className="pt-1 ">
              <a
                href="https://itunes.apple.com/us/app/disclosures-io/id1435244080?mt=8"
                target="_blank"
                rel="noopener noreferrer"
                universal="true"
                style={{color: '#46b6ff'}}>
                <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                  &nbsp;<span style={{textTransform: 'lowercase'}}>i</span>OS APP
                </div>
              </a>
            </div>

            <div className=" ">
              <button className="col-12 bg-white" style={{border: '0px solid blue', paddingLeft: '5px'}}>
                <details className="accordion" closed="true">
                  <input type="checkbox" hidden={true} />
                  <summary className=" accordion-header  btn btn-md " style={{border: '0px solid blue', borderRadius: '0', color: '#46b6ff'}}>
                    <div className="panel-header  col-12 col-mx-auto text-left  " style={{border: '0px solid blue', paddingTop: '.4rem'}}>
                      &nbsp;Connect With Us &nbsp; <i className="icon  icon-arrow-right mr-1 float-right" />
                    </div>
                  </summary>
                  <div className="accordion-body bg-white zvideobottom">
                    <div>
                      <ul className="text-small text-left " style={{listStyle: 'none', paddingLeft: '.2rem'}}>
                        <a href="https://www.facebook.com/disclosures.io" style={{color: '#46b6ff'}} rel="noopener noreferrer" target="_blank" universal="true">
                          <li>
                            <i className="fab fa-facebook-square fa-lg" style={{color: '#46b6ff'}} /> &nbsp; FACEBOOK
                          </li>
                        </a>
                        <a
                          href="https://www.instagram.com/disclosures.io"
                          style={{color: '#46b6ff'}}
                          rel="noopener noreferrer"
                          target="_blank"
                          universal="true">
                          <li className="pt-2">
                            <i className="fab fa-twitter-square fa-lg" style={{color: '#46b6ff'}} />
                            &nbsp; INSTAGRAM
                          </li>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/disclosuresio"
                          style={{color: '#46b6ff'}}
                          rel="noopener noreferrer"
                          target="_blank"
                          universal="true">
                          <li className="pt-2">
                            <i className="fab fa-linkedin fa-lg" style={{color: '#46b6ff'}} /> &nbsp; LINKEDIN
                          </li>
                        </a>
                        <a href="https://twitter.com/disclosuresio" style={{color: '#46b6ff'}} rel="noopener noreferrer" target="_blank" universal="true">
                          <li className="pt-2">
                            <i className="fab fa-twitter-square fa-lg" style={{color: '#46b6ff'}} /> &nbsp; TWITTER
                          </li>
                        </a>
                        <a
                          className=""
                          href={'https://active.disclosures.io/'}
                          style={{color: '#46b6ff'}}
                          target="_blank"
                          rel="noopener noreferrer"
                          universal="true">
                          <li className="pt-2 pb-8">
                            <i className="fas fa-glasses fa-lg" style={{color: '#46b6ff'}} /> &nbsp; BLOG
                          </li>
                        </a>
                      </ul>
                    </div>
                  </div>
                </details>
              </button>
            </div>

            <div className="pt-1 ">
              <a href="https://w.disclosures.io/lunch-and-learn" target="_blank" rel="noopener noreferrer" universal="true" style={{color: '#46b6ff'}}>
                <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                  &nbsp;Join the next Lunch-and-Learn
                  <i className="icon  icon-arrow-right mr-1 float-right" />
                </div>
              </a>
            </div>

            <div
              className="col-12 border-top  text-right  zvideobackback"
              style={{
                position: 'fixed',
                bottom: '1%',
                right: '0%',
                paddingTop: '1rem',
                paddingBottom: '0rem',
              }}>
              <h4 class="pb-0 text-center px-8">Getting started is easy, and free!</h4>
              <p class="pb-0 px-8 text-center">Enter the email that you would like as your main account login. </p>

              <SignupFormMob size="sm" />

              <div className="pt-4 pb-16 text-center">
                Already have an account? <br />
                <a href={`https://app.disclosures.io/auth/login`} style={{color: '#46b6ff'}}>
                  <button className="btn  btn-md col-12 " style={{border: '0 solid blue'}}>
                    Log In Here
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div key="body">
            <div className=" th-home--navbar-container bg-white  ">
              <div className="container grid-xl">
                <Navbar onClickMenu={this.onClick} />
              </div>
            </div>
            <div className="pt-6 mt-8">
              <div>{this.props.children}</div>
              <Footer />
            </div>
          </div>
        </OffCanvas>
      </>
    )
  }
}
