import React from 'react'
import SignupForm from '../components/SignupForm'
import SignupFormMob from './SignupFormMob'
import SignupFormNav from './SignupFormNav'

import {StaticQuery, graphql} from 'gatsby'

function Navbar({onClickMenu, className}) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              appUrl
              logo {
                light
              }
            }
          }
        }
      `}
      render={data => {
        const appUrl = data.site.siteMetadata.appUrl
        const logoUrl = 'https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/hlm-logos-2021/listing-management-color-light.png'
        return (
          <>
            <div className={`th-home--navbar  navbar bg-white  ${className || ''}`} style={{width: '100%'}}>
              <div className="navbar-section bg-white th-home--navbar-logo ">
                <a href="/" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                  <img src={logoUrl} alt="HomeLight Listing Management (HLM)" style={{height: '2rem'}} />
                  {/* <span className="navbar-brand text-white " style={{paddingLeft: '.5rem', textDecoration: 'none'}}>
                    HomeLight Listing Management (HLM)
                  </span> */}
                </a>
              </div>

              {/* MOBILE BELOW */}
              <div className=" navbar-section text-right  container grid-xl show-md show-lg ">
                <div className="dropdown dropdown-right ">
                  <a className="btn btn-action btn-primary dropdown-toggle" tabIndex="0" onClick={onClickMenu}>
                    <i className="icon icon-menu" style={{color: '#ffffff'}} />
                  </a>
                </div>
                {/* NAV BAR MOBILE 
                <div className="hide-md">
                  <a href="/demo" className="btn btn-link">
                    <i className="fas fa-video" /> &nbsp; How it Work
                  </a>

                  <a href="/features" className="btn btn-link">
                    Features
                  </a>

                  <a className="btn btn-link" href="/learn#anchor-howitworks">
                    How it Works
                  </a>
                  <a className="btn btn-link" href="/pricing">
                    Pricing
                  </a>
                  <a className="btn btn-link" href={`${appUrl}/auth/login`}>
                    Log In
                  </a>
                  <a className="btn btn-primary" href={`${appUrl}/auth/signup`} style={{padding: '.5em 4em 1em 4em'}}>
                    Sign up
                  </a>
                </div>{' '}
                */}
              </div>

              {/* DESKTOP BELOW */}

              <div className="navbar-section  bg-white hide-md hide-sm hide-lg" style={{}}>
                {/* <a href="/demo" className="nav-link">
                  <i className="fas fa-video" />
                  &nbsp; How it Works
                </a> */}

                <a href="/features" className="nav-link2">
                  Features
                </a>

                <a href="/agents" className="nav-link">
                  <i class="fas fa-hands-helping"></i>
                  &nbsp; Services
                </a>

                {/* <a className="nav-link2" href="/learn#anchor-howitworks">
                  How it Works
                </a> */}
                <a className="nav-link2" href="/pricing">
                  Pricing
                </a>
                {/* <a
                  className="nav-link2"
                  href="https://itunes.apple.com/us/app/disclosures-io/id1435244080?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  universal="true">
                  <i className="fab fa-apple " />
                  &nbsp; <span style={{textTransform: 'lowercase'}}>i</span>
                  OS App
               </a> */}
                <a className="nav-link2" href={`${appUrl}/auth/login`} style={{}}>
                  Log In
                </a>

                <div className=" ">
                  <div className="columns">
                    <div className="column container grid-xl">
                      <a href="#signup-mod" className="nav-link4">
                        Sign Up
                      </a>
                      <div className="modal modal-lg pt-20 text-primary " id="signup-mod" style={{height: '100%'}}>
                        <a href="#" className=" modal-overlay text-primary" aria-label="Close" />
                        <div className="modal-container modaluni " role="document" style={{position: 'fixed', right: '0%', height: '100%'}}>
                          <a href="#" className=" text-right" aria-label="Close">
                            <i class="far fa-times-circle text-dark fa-lg"></i>
                          </a>
                          <div className="modal-body  text-center modal-body-uni text-dark">
                            <h3 class="pb-2 text-center">Getting started is easy, and free!</h3>
                            <p class="pb-6">Enter the email that you would like as your main account logins. </p>

                            <div className="px-12">
                              <SignupFormMob size="md" />
                            </div>
                            <img
                              className=" img-responsive pt-16 container "
                              src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/hlm-logos-2021/listing-management-color-light.png"
                              alt="HomeLight Listing Management (HLM)"
                              width="300"
                              height="auto"
                              style={{maxWidth: '250px'}}
                            />
                            <p class="pt-12 text-small">
                              Already have an account?{' '}
                              <a href="https://app.disclosures.io/auth/login" target="_blank">
                                Log In Here
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a>
                  {/* <div class="hide-md" style={{marginLeft: '20px', marginTop: '1rem'}}>
                    <SignupFormNav size="md" />
                  </div> */}
                </a>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default Navbar
